/**
 * Created by LittleRedHat on 2016/7/12.
 */
var index_param = {
    debut_start:0,
    debut_limit:5,
    debut_loading:false
};
$(document).ready(function () {
    /*
     get slider and ids
     */
    getSliderAndAds();
    /*
     get lastest firstpub
     */
    $('.btn-more').on('click',function(event){
        var $btn = $(this);
        if(index_param.debut_loading){
            return;
        }
        index_param.debut_loading = true;
        var promise = getLatestDebut(index_param.debut_start,index_param.debut_limit);
        promise.done(function(data){
            var length = data.firstpubs.length;
            if(length < index_param.debut_limit){
                index_param.debut_loading = true;
                $btn.slideUp();
            }else{
                index_param.debut_loading = false;
            }
        });
    });
    var promise = getLatestDebut(index_param.debut_start,index_param.debut_limit);
    promise.done(function(){
        $('.btn-more').show();
    });

    /*
     get lastest activity
     */
    getLatestActivity(0,3);


    /*
     delegate event to debut_list
     */
    $('#debut_list').on('mouseenter', '.ui.card', function (event) {
        var $share = $(this).find('.share');
        $share.share({sites: ['qq', 'wechat', 'weibo']});
        $share.css('visibility','visible');
        $share.show();
    }).on('mouseleave', '.ui.card', function (event) {
        var $share = $(this).find('.share');
        $share.css('visibility','hidden');
        $share.hide();
    })
});
function getLatestActivity(start, limit) {
    var promise = latestActivities(start, limit);
    promise.done(function (data) {
        var activity = {
            activities: data.activities.slice(0, 3)
        };
        $.each(activity.activities, function (index, value) {
            // var t = new Date(value.start_time);

            value.month = value.start_time_month;
            value.day = value.start_time_day;
            value.time = value.start_time_time;
        });
        $('#latest-activity').html(S['templates']['lastest_activity'](activity));
    });
}
function getSliderAndAds() {
    var promise = homepageInfo();
    promise.done(function (data) {
        var slider_data = data;
        var adver1 = {
            url: data.ads[0]
        };
        var adver2 = {
            url: data.ads[1]
        };
        var activity = data.activities[0];
        $('#slider').html(S['templates']['debut_slider'](slider_data));
        $('#slider').nivoSlider({});
        $('#adver1').html(S['templates']['adver'](adver1));
        $('#adver2').html(S['templates']['adver'](adver2));
        // $('#slide-activity').html(S['templates']['homepage_slider_activity'](activity))
    });
    return promise;
}
function getLatestDebut(start, limit) {
    var promise = latestDebuts(start, limit);
    promise.done(function (data) {
        $('#debut_list').append(S['templates']['debut_item'](data));
        index_param.debut_start = index_param.debut_start + data.firstpubs.length;
    });
    return promise;
}


/*
 fetch/post data,return promise for reuse
 */
function homepageInfo() {
    return $.ajax({
        url: server_base + "/showpage/homepage",
        method: 'GET',
        dataType: "json"
    });


}
function latestDebuts(start, limit) {
    return $.ajax({
        url: server_base + "/firstpubs/summary/all",
        method: 'GET',
        dataType: "json",
        data: {
            start: start,
            limit: limit
        }
    });
}

function latestActivities(start, limit) {
    return $.ajax({
        url: server_base + "/activity/get/type/all",
        data: {
            start: start,
            limit: limit
        },
        method: 'GET',
        dataType: "json"
    });
}

































































